<div fxLayout="row"  class="title-notif">
    <h3 class="title">
        <mat-icon class="notifications-close" (click)="close()">arrow_back</mat-icon>
        <span>Notifications</span>
    </h3>
    <div class="nav-buttons">
        <span class="read-all" (click)="readAll(selectedTab)"><u>Marquer comme lu</u></span>
        <mat-icon class="notifications-icon">notifications_on</mat-icon>
    </div>
</div>

<div class="custom-tab-group">
    <div class="custom-tab-labels-box">
        <div class="custom-tab-labels">
            <div class="custom-tab-label" [class.active]="selectedTab === 0" (click)="selectTab(0)">
                <div class="label-text">Tous <span *ngIf="unreadNotifs('all')"><b>{{unreadNotifs("all")}}</b></span></div>

            </div>
            <div class="custom-tab-label" [class.active]="selectedTab === 1" (click)="selectTab(1)">
                <div class="label-text">Candidats <span *ngIf="unreadNotifs('candidate')"><b>{{unreadNotifs("candidate")}}</b></span></div>
    
            </div>
            <div class="custom-tab-label" [class.active]="selectedTab === 2" (click)="selectTab(2)">
                <div class="label-text">Equipe <span *ngIf="unreadNotifs('team')"><b>{{unreadNotifs("team")}}</b></span></div>

            </div>
            <!-- <div class="custom-tab-label" [class.active]="selectedTab === 3" (click)="selectTab(3)">
                <div class="label-text">Système <span *ngIf="unreadNotifs('system')>0"><b>{{unreadNotifs("system")}}</b></span></div>
                <div class="notification-icon">
                    <mat-icon class="icon fs-1">settings</mat-icon>
                    <span *ngIf="unreadNotifs('system')>0" class="notification-count"><b>{{unreadNotifTen(unreadNotifs("system"))}}</b></span>
                </div>
    
            </div> -->
            <div class="custom-tab-label" [class.active]="selectedTab === 4" (click)="selectTab(4)">
                <div class="label-text">Examen <span *ngIf="unreadNotifs('exam')"><b>{{unreadNotifs("exam")}}</b></span></div>
            </div>
        </div>
    </div>

    <div #scrollableDiv class="custom-tab-content" [@slideInOut]="selectedTab">
        <div *ngIf="selectedTab === 0" class="tab-content">
            <div *ngFor="let notification of notifs[selectedTab]">
                <div>
                    <app-notification-box *ngIf="emptyNotifList[identifyList(selectedTab)]" [notification]="emptuNotifFormat" [selectedTab]="selectedTab" ></app-notification-box>
                                    <!-- Check in the parent template -->
                </div>

                <app-notification-box [notification] = "notification"></app-notification-box>
            </div>
            <div class="reload-box">
                <mat-icon  class="reload-icon" *ngIf="loadingData" [class.rotate]="loadingData">refresh</mat-icon>
                <span class="read-all" *ngIf="!loadingData && !emptyNotifList[identifyList(selectedTab)]" (click)="loadingMore(selectedTab)"  ><u>Afficher plus</u></span>
            </div>
        </div>
        <div *ngIf="selectedTab === 1" class="tab-content">
            <div>
                <app-notification-box *ngIf="emptyNotifList[identifyList(selectedTab)]" [notification]= "emptuNotifFormat" [selectedTab]="selectedTab" ></app-notification-box>
            </div>
            <div *ngFor="let notification of notifs[selectedTab]">
                <app-notification-box [notification] = "notification"></app-notification-box>
            </div>
            <div class="reload-box">
                <mat-icon  class="reload-icon" *ngIf="loadingData" [class.rotate]="loadingData">refresh</mat-icon>
                <span class="read-all" *ngIf="!loadingData && !emptyNotifList[identifyList(selectedTab)]" (click)="loadingMore(selectedTab)"  ><u>Afficher plus</u></span>
            </div>
        </div>
      <div *ngIf="selectedTab === 2" class="tab-content">
        <div>
            <app-notification-box *ngIf="emptyNotifList[identifyList(selectedTab)]" [notification]= "emptuNotifFormat" [selectedTab]="selectedTab"></app-notification-box>
        </div>
        <div 
        *ngFor="let notif of notifs[selectedTab]"
        >
            <app-notification-box [notification]= "notif"></app-notification-box>
        </div>
        <div class="reload-box">
            <mat-icon  class="reload-icon" *ngIf="loadingData" [class.rotate]="loadingData">refresh</mat-icon>
            <span class="read-all" *ngIf="!loadingData && !emptyNotifList[identifyList(selectedTab)]" (click)="loadingMore(selectedTab)"  ><u>Afficher plus</u></span>
        </div>
      </div>
      <div *ngIf="selectedTab === 3" class="tab-content">
        <div>
            <app-notification-box *ngIf="emptyNotifList[identifyList(selectedTab)]" [notification]= "emptuNotifFormat" [selectedTab]="selectedTab"></app-notification-box>
        </div>
        <div 
        *ngFor="let notif of notifs[selectedTab]"
        >
            <app-notification-box [notification]= "notif"></app-notification-box>
        </div>
        <div class="reload-box">
            <mat-icon  class="reload-icon" *ngIf="loadingData" [class.rotate]="loadingData">refresh</mat-icon>
            <span class="read-all" *ngIf="!loadingData && !emptyNotifList[identifyList(selectedTab)]" (click)="loadingMore(selectedTab)"  ><u>Afficher plus</u></span>
        </div>
      </div>
      <div *ngIf="selectedTab === 4" class="tab-content">
        <div>
            <app-notification-box *ngIf="emptyNotifList[identifyList(selectedTab)]" [notification]= "emptuNotifFormat" [selectedTab]="selectedTab"></app-notification-box>
        </div>
        <div 
        *ngFor="let notif of notifs[selectedTab]"
        >
            <app-notification-box [notification]= "notif"></app-notification-box>
        </div>
        <div class="reload-box">
            <mat-icon  class="reload-icon" *ngIf="loadingData" [class.rotate]="loadingData">refresh</mat-icon>
            <span class="read-all" *ngIf="!loadingData && !emptyNotifList[identifyList(selectedTab)]" (click)="loadingMore(selectedTab)"  ><u>Afficher plus</u></span>
        </div>
      </div>
    </div>
</div>