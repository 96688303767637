 
export const environment = {
    uriG: "https://app.autoecoleplus.tn",
    urlFront: "https://app.autoecoleplus.tn",
    urlSocket: "https://app.autoecoleplus.tn/Agence",
    urlRealTime: "https://app.autoecoleplus.tn/real-time/api",
    urlRealTimeLocal: "https://app.autoecoleplus.tn/real-time/api",
    paymeeLink: "https://app.paymee.tn/",
    paymeeToken: "d021c0fd01606f026d35b22719f754a4c2f384f8",
    localPMVendor:1825,
    localPMToken:"34286b226bc3397731a3e95e72f3832e9da5c074",
    localPMLink:"https://sandbox.paymee.tn/",
    vendor:15917,
    production: true,
    hmr: false,
    soloLinkingUrl: "https://autoecole.com.tn"
};

// export const environment = {
//     uriG: "",
//     urlFront: "",
//     urlSocket: "http://localhost:3005/Agence",
//     urlRealTime: "http://localhost:3005/real-time/api",
//     urlRealTimeLocal: "http://localhost:3005/",
//     paymeeLink: "https://app.paymee.tn/",
//     paymeeToken: "d021c0fd01606f026d35b22719f754a4c2f384f8",
//     localPMVendor:1825,
//     localPMToken:"34286b226bc3397731a3e95e72f3832e9da5c074",
//     localPMLink:"https://sandbox.paymee.tn/",
//     vendor:15917,
//     production: true,
//     hmr: false,
//     soloLinkingUrl: "https://autoecole.com.tn"
// };