import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavbarService } from "./layout/components/navbar/navbar.service";
import { BehaviorSubject,  Subject } from "rxjs";
import { Injectable } from "@angular/core";
import io from "socket.io-client";
import { SwPush } from '@angular/service-worker';
import { take } from 'rxjs';
import { ExamNotificationDialogComponent } from './main/plus/components/exam-notification-dialog/exam-notification-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
    providedIn: "root",
})
export class RealTimeService {

    r = Math.random();
    j = 0;
    readonly VAPID_PUBLIC_KEY = "BGDJZDMHtY_I1FTHJOr45-FIQW1DFaavvDFUnrjXGnWNosXP361gKxptvHnukyY8f5SdbZVZh9Rl0wcs217RVCQ";

    socket;

    //* if we are in production but still in localhost
    urlSocket = !location.origin.includes('localhost') ?  environment.urlSocket : "http://localhost:3005/Agence";
    pushSubscription = null;
    urlRealTime = environment.urlRealTime + "/account/";
    uri = environment.uriG;
    ip = 'undefined';
    timer = null;
    id = null;
    agenceId = null;

    dialogRef: any;

    notifications = []

    realTimeFollowUps: Subject<any> = new Subject<any>();
    realTimaQuestion: Subject<any> = new Subject<any>();
    realTimaNotification: Subject<any> = new Subject<any>();
    private exam_list_notifications:  BehaviorSubject<any> = new BehaviorSubject<any>([])


    constructor(private navbarService: NavbarService,
        private swPush: SwPush,
        private http: HttpClient,
        private _matDialog: MatDialog,
        private router: Router,

        ) {


        }



    get examListNotifications(){
        return this.exam_list_notifications.asObservable()
    }

    notificationOpened(id) {
        console.log("notification is opened", id);
        return this.http.put<any>(
            `${this.uri}/api/notifications/opened/`,
            {ids:id}
        );
    }
    
    loggedIn() {
        return !!localStorage.getItem("token");

      }


    resetExamListNotification(){
        const notificationIds = this.exam_list_notifications.value.map(notification => notification.notification_id);
        this.notificationOpened(notificationIds).subscribe(() => {
            console.log("notification.notification_id set to opned" , notificationIds)  
            this.exam_list_notifications.next([]);
          })

    }

    if2NotificationIsTheSame(notification1, notification2) {
        const { notification_id: _, ...notificationWithoutId1 } = notification1;
        const { notification_id: __, ...notificationWithoutId2 } = notification2;

        return JSON.stringify(notificationWithoutId1) === JSON.stringify(notificationWithoutId2);
    }
    addExamNotificationToExamDialog(notification){
        if(notification) {
            const currentUrl = this.router.url; // Get the current URL

            if (
                currentUrl !== '/nointernet' &&
                currentUrl !== '/auth/blocked' &&
                this.loggedIn()
            ) {
                console.log("notification received");
        
                const exam_list_id = notification.contentId;
                if (!!exam_list_id) {
                    this.getExamNotificationDetails(exam_list_id).subscribe((data: any) => {
        
                        const notificationExists = this.exam_list_notifications.value.some(
                            existingNotification => this.if2NotificationIsTheSame(existingNotification, data.data)
                        );
        
                        let newExamListNotifications;
        
                        if (!notificationExists) {
                            const newNotification = { ...data.data, notification_id: notification.id };
                            newExamListNotifications = this.exam_list_notifications.value.concat([newNotification]);
                        } else {
                            newExamListNotifications = [...this.exam_list_notifications.value];
                        }
        
                        this.exam_list_notifications.next(newExamListNotifications);
                        this.openExamNotificationDialog();
                    });
                }
            }
                
        }
        
    }

    openExamNotificationDialog(){
            const isDialogOpen = this._matDialog.openDialogs.some(dialogRef => dialogRef.componentInstance instanceof ExamNotificationDialogComponent);
            setTimeout(() => {
                const isDialogOpen = this._matDialog.openDialogs.some(dialogRef => dialogRef.componentInstance instanceof ExamNotificationDialogComponent);
                if (!isDialogOpen) {

                    this.dialogRef = this._matDialog.open(ExamNotificationDialogComponent, {
                        panelClass: ["common-dialog-style"],
                        data: {
                        },
                        width:'800px'
                    });
                    this.dialogRef.afterClosed().pipe(take(1)).subscribe((response) => {
                        this.resetExamListNotification();
                    });
                }
            },2000);

    }

    getExamNotificationDetails(exam_list_id){
        return this.http.get(`${this.uri}/api/centers-settings/exam-list-center-details/${exam_list_id}`)
    }

    

    connectSocket(id, agenceId, role) {
       if(environment.production){
        this.id = id;
        this.agenceId = agenceId;
        if (!this.socket) {
            this.socket = io(this.urlSocket, {
                secure:true,
                rejectUnauthorized: false,
                query: {
                    "userId":id,
                    "agenceId":agenceId,
                    "role":role   
                }
            });
            //`userId=${id}&agenceId=${agenceId}&role=${role}`,

            this.socket.on("msg", (msg) => {
            });

            this.socket.on("notification", (msg) => {
                console.log("notification")
            });

            // ? listen to question Notifications
            this.socket.on("follow-up", (followUP) => {
                this.realTimeFollowUps.next(followUP);
            });

            // ? listen to question Notifications
            this.socket.on("question", (question) => {
                let x = this.navbarService.getBadgeCount("Candidat-Code");
                x++;
                this.navbarService.setBadgeValue("Candidat-Code", {
                    title: "" + x,
                    fg: "white",
                    bg: "red",
                });
                this.navbarService.setChildBadgeValue(
                    "Candidat-Code",
                    "Candidat-Questions",
                    {
                        title: "" + x,
                        fg: "white",
                        bg: "red",
                    }
                );
                this.realTimaQuestion.next(question);
            });

            // ? listen to other Notifications
            this.socket.on("notification-ready", (notification) => {
                this.realTimaNotification.next(notification);
                if(notification["contentTable"] == 'exam_list'){
                    this.addExamNotificationToExamDialog(notification)
                }
            });
        }
        this.startPing(id,agenceId);
        this.loadIp();
    }}

    disconnectNotification(){
        this.id = null;
        this.agenceId = null;
        this.disconnectSocket();
        this.unsubscribeFromPushNotification();
        this.stopPing();
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect();
            //this.socket.emit("disconnect");
            this.socket = null;
        }
    }

    getRealTimeQuestion() {
        return this.realTimaQuestion.asObservable();
    }

    getRealTimeFollowUps() {
        return this.realTimeFollowUps.asObservable();
    }

    getRealTimeNotification() {
        return this.realTimaNotification.asObservable();
    }

    subscribeToNotifications(compte_id) {

        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        })
        .then(sub => 
            {
                let subscription = {compte_id:compte_id,subscription:sub};
                this.pushSubscription = subscription;

                this.addPushSubscriber(subscription).subscribe(res=>{
                    console.log('swPushres',res);

                    this.swPush.notificationClicks.subscribe( arg =>
                        {
                          console.log('swPusharg',arg);
                       });
                })
            }
            )
        .catch(err => {console.log('swpush',err)});
    }

    //? NOTIFICATION SUBS
    unsubscribeFromPushNotification(){
        let params = new HttpParams();
        if(this.pushSubscription){
        //params = params.append("endpointUrl", this.pushSubscription.subscription.endpoint);
            console.log(this.pushSubscription.subscription.endpoint)

            this.http.delete(`${this.urlRealTime}pushservice`,{params: {endpointUrl:this.pushSubscription.subscription.endpoint}})
                .subscribe({next:res=>{
                    return
                },
                error:err=>{
                   // console.log('ERRfirst :',err)
                }})
        }
    }


    

    addPushSubscriber(e){
        console.log('e',e)
        return this.http.post(`${this.urlRealTime}pushservice`,e)
    }

    //? SESSION 

    loadIp(){
        this.http.get("https://api.ipify.org/?format=json")
          .subscribe({next:(response:any)=>{
            this.ip = response.ip     
        },error:(err) => {
          return
        }})
      }
      
      ping(id,agenceId){
        this.http.post(`${this.urlRealTime}session-ping/${id}/${this.ip}/${agenceId}`,{},{ withCredentials: true })
        .pipe(take(1))
        .subscribe(
            {
                next: () => {  },
                error: () => { return },    
            }
            )
      }
    
      startPing(id,agenceId){
        //console.log('STARTING PING');

        setTimeout(()=>{this.ping(id,agenceId)},2000) ;
        this.timer = setInterval(()=>{this.ping(id,agenceId)},50000)
      }
    
      stopPing() {
        //console.log('STOPING PING');

        if(this.timer){
          clearInterval(this.timer)
          this.timer = null;
          //! if there is more then one active tab just activeTab--
          this.http.post(`${this.urlRealTime}session-destroy`,{},{ withCredentials: true })
            .pipe(take(1))
            .subscribe(
                {
                    next: () => {  },
                    error: () => { return },    
                }
                )
            }
      }

      holdPing() {
        clearInterval(this.timer)
        this.timer = null;
      }
    
      resumePinging() {

        if(!this.timer && this.id){
            this.startPing(this.id,this.agenceId)
        }
      }    

}
