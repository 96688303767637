<h1 matDialogTitle *ngIf="this.type =='selfAccount' || this.type =='userAccount' ||  this.type =='memberAccount' ">
  changer mot de passe
</h1>
<h1 matDialogTitle *ngIf="this.type =='telLogin' ">changer tel login</h1>

<form *ngIf="changePasswordForm" autocomplete="off" [formGroup]="changePasswordForm">

  <!-- SELF ACCouNT  -->

  <div *ngIf="this.type =='selfAccount'">
    <div fxLayout="column">
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Ancien mot de passe</mat-label>
        <input autocomplete="off" matInput [type]="password_type" name="ancien mot de passe" formControlName="ancienpassword"
          placeholder="Ancien mot de passe" (keyup)="check($event)" required>
          <mat-icon matSuffix style="cursor: pointer;" (click)="password_icon_click()">{{ password_icon }}
          </mat-icon>
          <mat-error>
            Le mot de passe est erroné. 
          </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
        <mat-label>Nouveau mot de passe</mat-label>
        <input autocomplete="off" matInput [type]="password_type" name="nouveau mot de passe" formControlName="newpassword"
          (keyup)="markTouched($event);check($event)" placeholder="Nouveau mot de passe" required minlength="6">
          <mat-icon matSuffix style="cursor: pointer;" (click)="password_icon_click()">{{ password_icon }}
          </mat-icon>
          <mat-error>
          Mot de passe trés court
        </mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Confirmer mot de passe</mat-label>
        <input autocomplete="off" matInput [type]="password_type" name="confirmer nouveau mot de passe"
          formControlName="confirmpassword" (keyup)="check($event)" placeholder="Confirmer mot de passe" required>
          <mat-icon matSuffix style="cursor: pointer;" (click)="password_icon_click()">{{ password_icon }}
          </mat-icon>
      </mat-form-field>
      <div class="motpasseidentique"
          *ngIf=" this.changePasswordForm?.controls['confirmpassword'].value == this.changePasswordForm?.controls['newpassword'].value &&
        this.changePasswordForm?.controls['newpassword'].value != null &&
        this.changePasswordForm?.controls['confirmpassword'].value != null &&  this.changePasswordForm?.controls['newpassword'].value.length>5">Mots de
          passe identiques</div>
        <div class="motpassepasidentique"
          *ngIf=" this.changePasswordForm?.controls['confirmpassword'].value != this.changePasswordForm?.controls['newpassword'].value &&
        this.changePasswordForm?.controls['newpassword'].value != null &&
        this.changePasswordForm?.controls['confirmpassword'].value != null &&  this.changePasswordForm?.controls['newpassword'].value.length>5">Les mots
          de passe ne sont pas identiques</div>
      </div>
      <mat-checkbox formControlName="disconnect_devices" class="pt-24">déconnecter tous les appareils !</mat-checkbox>

      
    <div mat-dialog-actions>
      <button mat-raised-button 
        [mat-dialog-close]="changePasswordForm ? {
                             password:this.changePasswordForm?.controls['newpassword']?.value,
                             disconnect_devices:this.changePasswordForm?.controls['disconnect_devices']?.value
                            }
                            : null"
        cdkFocusInitial [disabled]="checked == 0" class="mat-accent mr-16">Confirmer</button>
      <button mat-button (click)="onCancel()">Annuler</button>
    </div>
  </div>

  <!-- USER ACCouNT  -->

  <div *ngIf="this.type =='userAccount'">
    <div fxLayout="column">

      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Nouveau mot de passe</mat-label>
        <input autocomplete="off" matInput [type]="password_type" [ngModelOptions]="{standalone: true}" name="nouveau mot de passe" [(ngModel)]="newpassword"
          placeholder="Nouveau mot de passe" required minlength="6">
          <mat-icon matSuffix style="cursor: pointer;" (click)="password_icon_click()">{{ password_icon }}
          </mat-icon>
          <mat-error>
          Mot de passe trés court
        </mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="pt-24">
      <button mat-raised-button [mat-dialog-close]="newpassword" cdkFocusInitial [disabled]="newpassword.length <6"
        class="mat-accent mr-16">Confirmer</button>
      <button mat-button (click)="onCancel()">Annuler</button>
    </div>
  </div>

  <!-- MEMBRE ACCouNT -->
  <div *ngIf="this.type =='memberAccount'">
    <div fxLayout="column">

      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Nouveau mot de passe</mat-label>
        <input autocomplete="off" matInput [type]="password_type" name="nouveau mot de passe" [ngModelOptions]="{standalone: true}" [(ngModel)]="newpassword"
          placeholder="Nouveau mot de passe" required minlength="6">
          <mat-icon matSuffix style="cursor: pointer;" (click)="password_icon_click()">{{ password_icon }}
          </mat-icon>
        <mat-error>
          Mot de passe trés court
        </mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="pt-24">
      <button mat-raised-button [mat-dialog-close]="newpassword" cdkFocusInitial [disabled]="newpassword.length <6"
        class="mat-accent mr-16">Confirmer</button>
      <button mat-button (click)="onCancel()">Annuler</button>
    </div>
  </div>

  <!-- TEL LOGIN -->
  <div *ngIf="this.type =='telLogin'">
    <form [formGroup]="ChangerUsernameForm" autocomplete="off">

      <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
        <mat-label> Tel login </mat-label>
        <input autocomplete="off" matInput name="login" required formControlName="username"
          placeholder="Tel login (لازم)" pattern="^[0-9]{8}$" />
        <mat-error>
          ce champ est requis
        </mat-error>
      </mat-form-field>
      <div *ngIf="error" style="background-color: rgb(228, 177, 228); padding: 24px; border-radius: 5px"
        class="register" fxLayout="column" fxLayoutAlign="start start">
        <span class="text">Telephone déja Utilisé</span>
      </div>

      <div mat-dialog-actions class="pt-24" fxLayout="row">
        <button mat-raised-button class="mat-accent mr-16" [disabled]="ChangerUsernameForm.invalid"
          (click)="changeUserName()">
          Confirmer
        </button>
        <button mat-button (click)="onCancel()">Annuler</button>
      </div>
    </form>
  </div>