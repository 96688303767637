import { AuthentificationService } from "./authentification.service";
import { Injectable, Injector } from "@angular/core";
import {
    HttpInterceptor,
    HttpErrorResponse,
    HttpEvent,
    HttpResponse,
    HttpRequest,
} from "@angular/common/http";
import { catchError, retry, tap } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { Router } from "@angular/router";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
    constructor(
        private router: Router,
        private injector: Injector,
        private loaderService: FuseProgressBarService
    ) {}

    intercept(req, next) {
        if(!req.url.includes('real-time')) this.showLoader();
        //*real tim & localhost
        else if(location.origin.includes("localhost")){
            const params = req.params;
            const body = req.body;
            console.log('PARAMS',params)
            console.log('body',body);
            if(req.url.includes('pushservice')) console.log('req',req);

            //const httpRequest = new HttpRequest(<any>req.method,req.url.replace('https://app.autoecoleplus.tn/',environment.urlRealTimeLocal));
            const dupReq = req.clone({ url: req.url.replace('https://app.autoecoleplus.tn/',environment.urlRealTimeLocal) });

            req = Object.assign(req, dupReq)
            /* req.body = {...body};
            req.params = {...params} */
            if(req.url.includes('pushservice')) console.log('req',req);
        }
        let authService = this.injector.get(AuthentificationService);
        //if(authService.loggedIn()){
            let tokenizedReq = req;
            if(req.url != 'https://api.ipify.org/?format=json' && req.url.indexOf('sandbox') == -1 && req.url.indexOf('clarity') == -1 && req.url.indexOf('paymee') == -1){
            tokenizedReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${authService.getToken()}`,
                },
            });
            }
        
            return next.handle(tokenizedReq).pipe(
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.onEnd();
                    }
                }),
                retry(5),
                catchError((error, caught) => {
                    this.onEnd();

                    //intercept the respons error and displace it to the console
                    if(error.status === 401 && error.error.message =='logout') {
                        authService.logOut()
                        console.log('HANDELEIGNG')
                    }
                    this.handleAuthError(error);
                    return of(error);
                })
            ) as any;
        //}
        //else authService.logOut()
    }

    private onEnd(): void {
        this.hideLoader();
    }
    private showLoader(): void {
        this.loaderService.show();
    }
    private hideLoader(): void {
        this.loaderService.hide();
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        //handle your auth error or rethrow
        if (err.status === 401) {
            //navigate /delete cookies or whatever
            //this.router.navigate([`auth/login`]);
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            //this.router.navigate([`auth/login`]);
            /* if(err.error.message == 'logout') {
                return of(err.message);
            }
            else */ 
            throw err;
        }
        throw err;
    }
}
